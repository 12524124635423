import router from "@/router";
import memberService from "@/services/memberService";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    members: [],
    loading: false,
  },
  getters: {
    getMembers: (state) => state.members,
  },
  mutations: {
    SET_MEMBERS(state, members) {
      state.members = members;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async initMembers({ commit }) {
      try {
        const response = await memberService.getMembers();

        if (response.status === "success") {
          commit("SET_MEMBERS", response.data);
        }
      } catch (error) {
        return error.response;
      }
    },
    async createMember({ commit }, member) {
      try {
        commit("SET_LOADING", true);

        const body = {
          name: member.name,
          image: member.image,
        };

        const response = await memberService.createMember(body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: "Üye başarıyla oluşturuldu.",
          });
          router.push({ name: "member-list-page" });
        }

        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error.response.data.error);
        notify({
          type: "error",
          text: error.response.data.error,
        });
      }
    },
    async deleteMember({ commit }, id) {
      try {
        commit("SET_LOADING", true);
        const response = await memberService.deleteMember(id);

        if (response.status === "success") {
          commit(
            "SET_MEMBERS",
            this.state.member.members.filter(
              (member) => member.id !== id
            )
          );

          notify({
            type: "success",
            text: "Üye başarıyla silindi.",
          });
        }
        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error);
      }
    },
    async getMemberById(_, id) {
      try {
        const response = await memberService.getMemberById(id);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async updateMember(_, member) {
      try {
        const body = {
          name: member.name,
          image: member.image,
        };

        const response = await memberService.updateMember(member.id, body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: "Üye başarıyla güncellendi.",
          });
          router.push({ name: "member-list-page" });
        }

      } catch (error) {
        console.log(error.response.data.error);
        notify({
          type: "error",
          text: error.response.data.error,
        });
      }
    },

    async updateSortOrder(_, ids) {
      try {

        const body = {
          order: ids,
        };

        const response = await memberService.updateSortOrder(body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: "Sıralama başarıyla güncellendi.",
          });
        }
      } catch (error) {
        console.log(error.response);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },
  },
};
