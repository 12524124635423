import { appAxios } from "./appService";

export default {
  async getAllReferenceProjects() {
    const response = await appAxios.get("/get-reference-projects");
    return response.data;
  },
  async createReferenceProject(body) {
    const response = await appAxios.post("/admin/create-reference-project", body);
    return response.data;
  },
  async deleteReferenceProject(id) {
    const response = await appAxios.delete(`/admin/delete-reference-project/${id}`);
    return response.data;
  },
  async getReferenceProjectById(id) {
    const response = await appAxios.get(`/get-reference-project/${id}`);
    return response.data;
  },
  async updateReferenceProject(id, body) { 
    const response = await appAxios.put(`/admin/update-reference-project/${id}`, body);
    return response.data;
  },
  async updateSortOrder(body) { 
    const response = await appAxios.put(`/admin/update-reference-project-order`, body);
    return response.data;
  }
};