import { appAxios } from "./appService";

export default {
  async getAllServices() {
    const response = await appAxios.get("/get-services");
    return response.data;
  },
  async createService(body) {
    const response = await appAxios.post("/admin/create-new-service", body);
    return response.data;
  },
  async deleteService(serviceId) {
    const response = await appAxios.delete(`/admin/delete-service/${serviceId}`);
    return response.data;
  },
  async updateService(serviceId, body) {
    const response = await appAxios.put(`/admin/update-service/${serviceId}`, body);
    return response.data;
  },
  async getServiceById(serviceId) {
    const response = await appAxios.get(`/get-service/${serviceId}`);
    return response.data;
  },
  async updateSortOrder(body) {
    const response = await appAxios.put("/admin/update-service-order", body);
    return response.data;
  }
};
