<template>
  <app-bar :drawer="drawer" @updateDrawerValue="updateDrawer"></app-bar>
  <v-navigation-drawer v-model="drawer" :width="300" style="z-index: 99999;">
    <div class="navbar-logo-container">
      <img
        src="../assets/images/logo.jpg"
        alt=""
        class="navbar-logo"
      />
    </div>
    <v-list density="compact" nav>
      <v-list-group value="mainpage">
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-view-dashboard"
            title="Ana sayfa"
          ></v-list-item>
        </template>

        <v-list-item
          prepend-icon="mdi-movie-cog-outline"
          title="Video"
          value="video"
          @click="navigateTo('/video')"
        ></v-list-item>

        <v-list-item
          prepend-icon="mdi-text"
          title="Giriş Metni"
          value="feature"
          @click="navigateTo('/feature')"
        ></v-list-item>

        <v-list-item
          prepend-icon="mdi-text-account"
          title="Kurucu Sloganı"
          value="founder-slogan"
          @click="navigateTo('/founder-slogan')"
        ></v-list-item>

        <v-list-item
          prepend-icon="mdi-toolbox-outline"
          title="Hizmetler"
          value="service"
          @click="navigateTo('/service')"
        ></v-list-item>

        <v-list-item
        prepend-icon="mdi-highway"
        title="Yolculuğumuz"
        value="journey"
        @click="navigateTo('/journey')"
      ></v-list-item>

      <v-list-item
          prepend-icon="mdi-text-box-edit-outline"
          title="Sürdürülebilirlik"
          value="main-about"
          @click="navigateTo('/main-about')"
        ></v-list-item>

        <v-list-item
        prepend-icon="mdi-account-hard-hat-outline"
        title="Tamamlanan İşler"
        value="works"
        @click="navigateTo('/works')"
      ></v-list-item>

      <v-list-item
        prepend-icon="mdi-domain"
        title="Grup Şirketler"
        value="group-company"
        @click="navigateTo('/gcomp')"
      ></v-list-item>

      </v-list-group>

      <v-list-group value="about">
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-information-outline"
            title="Kurumsal"
          ></v-list-item>
        </template>

        <v-list-item
        prepend-icon="mdi-information-outline"
        title="Hakkımızda"
        value="about"
        @click="navigateTo('/about')"
      ></v-list-item>

      <v-list-item
        prepend-icon="mdi-text"
        title="Politikalarımız"
        value="policies"
        @click="navigateTo('/policies')"
      ></v-list-item>

      <v-list-item
        prepend-icon="mdi-account-group-outline"
        title="Üyelerimiz"
        value="members"
        @click="navigateTo('/members')"
      ></v-list-item>

      <v-list-item
        prepend-icon="mdi-highway"
        title="Yolculuğumuz"
        value="journey"
        @click="navigateTo('/journey')"
      ></v-list-item>
      </v-list-group>

      <v-list-group value="solitions">
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-rhombus-split-outline"
            title="Çözümlerimiz"
          ></v-list-item>
        </template>

        <v-list-item
          prepend-icon="mdi-rhombus-split-outline"
          title="Çözümlerimiz"
          value="solition"
          @click="navigateTo('/solition')"
        ></v-list-item>

        <v-list-item
          prepend-icon="mdi-layers-outline"
          title="Sistemler"
          value="system"
          @click="navigateTo('/system')"
        ></v-list-item>

        <v-list-item
          prepend-icon="mdi-application"
          title="Referans Uygulamalar"
          value="referencep"
          @click="navigateTo('/referencep')"
        ></v-list-item>

      </v-list-group>

      <v-list-group value="products">
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-chart-ppf"
            title="Ürünler"
          ></v-list-item>
        </template>

        <v-list-item
          prepend-icon="mdi-apps"
          title="Kategoriler"
          value="pcategory"
          @click="navigateTo('/pcategory')"
        ></v-list-item>

        <v-list-item
          prepend-icon="mdi-chart-ppf"
          title="Ürünler"
          value="product"
          @click="navigateTo('/product')"
        ></v-list-item>

        <v-list-item
          prepend-icon="mdi-view-agenda-outline"
          title="Temsilcilerimiz"
          value="agent"
          @click="navigateTo('/agent')"
        ></v-list-item>
      </v-list-group>

      <v-list-group value="surdurebilirik">
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-view-dashboard-edit-outline"
            title="Sürdürülebilirlik"
          ></v-list-item>
        </template>

        <v-list-item
          prepend-icon="mdi-text"
          title="Manifesto"
          value="smanifest"
          @click="navigateTo('/smanifest')"
        ></v-list-item>
        <v-list-item
          prepend-icon="mdi-square-rounded-badge-outline"
          title="Çevresel Göstergeler"
          value="envindicators"
          @click="navigateTo('/envindicators')"
        ></v-list-item>
        <v-list-item
          prepend-icon="mdi-head-lightbulb-outline"
          title="Ideathon"
          value="sideathon"
          @click="navigateTo('/sideathon')"
        ></v-list-item>
        <v-list-item
          prepend-icon="mdi-human-male-board-poll"
          title="İnsan Odaklılık Kategoriler"
          value="shumancentric-category"
          @click="navigateTo('/shumancentric-category')"
        ></v-list-item>
        <v-list-item
          prepend-icon="mdi-human-male-board-poll"
          title="İnsan Odaklılık"
          value="shumancentric"
          @click="navigateTo('/shumancentric')"
        ></v-list-item>
      </v-list-group>

      <v-list-group value="lifeinmert">
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-nature-people-outline"
            title="İnsan ve Kültür"
          ></v-list-item>
        </template>

        <v-list-item
          prepend-icon="mdi-nature-people-outline"
          title="Mert'te hayat"
          value="people-and-culture"
          @click="navigateTo('/people-and-culture')"
        ></v-list-item>
        <v-list-item
          prepend-icon="mdi-table-row"
          title="M.İçerikler"
          value="books"
          @click="navigateTo('/book')"
        > </v-list-item>
        <v-list-item
          prepend-icon="mdi-briefcase-outline"
          title="Kariyer"
          value="career"
          @click="navigateTo('/career')"
        ></v-list-item>
      </v-list-group>

      <!-- <v-list-item
        prepend-icon="mdi-sitemap-outline"
        title="Sektörler"
        value="sector"
        @click="navigateTo('/sector')"
      ></v-list-item> -->

      <v-list-group value="library">
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-library-outline"
            title="Kütüphane"
          ></v-list-item>
        </template>

        <v-list-item
          prepend-icon="mdi-newspaper-variant-outline"
          title="Bülten"
          value="newsletter"
          @click="navigateTo('/main-newsletter')"
        ></v-list-item>
        <v-list-item
          prepend-icon="mdi-post-outline"
          title="Blog"
          value="blog"
          @click="navigateTo('/blog')"
        ></v-list-item>

      </v-list-group>

      <v-list-group value="arge">
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-developer-board"
            title="Arge"
          ></v-list-item>
        </template>

        <v-list-item
          prepend-icon="mdi-image-edit-outline"
          title="Sayfa görseli"
          value="arge-banner-update"
          @click="navigateTo('/arge-banner-update')"
        ></v-list-item>
        <v-list-item
          prepend-icon="mdi-text-box-edit-outline"
          title="Giriş Metni"
          value="arge-content-update"
          @click="navigateTo('/arge-content-update')"
        ></v-list-item>
        <v-list-item
          prepend-icon="mdi-pencil-box-multiple-outline"
          title="AR-GE Projeler"
          value="argeproject"
          @click="navigateTo('/aproject')"
        ></v-list-item>
        <v-list-item
          prepend-icon="mdi-consolidate"
          title="AR-GE | İş Birlikleri"
          value="collab"
          @click="navigateTo('/collab')"
        ></v-list-item>
      </v-list-group>

      <v-list-item
        prepend-icon="mdi-human-greeting-proximity"
        title="İletişim"
        value="contact"
        @click="navigateTo('/contact')"
      ></v-list-item>

      <v-list-item
        prepend-icon="mdi-cog-outline"
        title="Site Ayarları"
        value="app-settings"
        @click="navigateTo('/app-settings')"
      ></v-list-item>

    </v-list>
  </v-navigation-drawer>
</template>
<script>
import AppBar from './AppBar.vue';
export default {
  name: "navigation-side",
  components: {
    AppBar,
  },
  data: () => ({
    drawer: true,
  }),
  methods: {
    navigateTo(route) {
      this.$router.push(route);
    },
    updateDrawer(value) {
      this.drawer = value;
    },
  },
};
</script>
