import router from "@/router";
import systemService from "@/services/systemService";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    systems: [],
    loading: false,
  },
  getters: {
    getSystems: (state) => state.systems,
  },
  mutations: {
    SET_SYSTEM(state, systems) {
      state.systems = systems;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async initSystems({ commit }, page) {
      try {
        const response = await systemService.getAllSystems(page);

        if (response.status === "success") {
          commit("SET_SYSTEM", response.data);
        }
      } catch (error) {
        return error.response;
      }
    },
    async createSystem({ commit }, form) {
      try {
        commit("SET_LOADING", true);

        const body = {
          title: form.title,
          description: form.description,
          en_title: form.en_title,
          en_description: form.en_description,
          image: form.image,
        };

        const response = await systemService.createSystem(body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "system-list-page" });
        }

        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error.response);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },

    async deleteSystem({ commit }, id) {
      try {
        commit("SET_LOADING", true);
        const response = await systemService.deleteSystem(id);

        if (response.status === "success") {
          commit(
            "SET_SYSTEM",
            this.state.system.systems.filter((system) => system.id !== id)
          );
        }
        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error);
      }
    },

    async getSystemById(_, id) {
      try {
        const response = await systemService.getSystemById(id);
        return response.data;
      } catch (error) {
        console.log(error);
        return error;
      }
    },

    async updateSystem(_, form) {
      try {
        const body = {
          title: form.title,
          description: form.description,
          en_title: form.en_title,
          en_description: form.en_description,
        };

        if (form.image) {
          body.image = form.image;
        }

        const response = await systemService.updateSystem(form.id, body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "system-list-page" });
        }

      } catch (error) {
        console.log(error.response);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },

    async updateSortOrder(_, ids) {
      try {

        const body = {
          order: ids,
        };

        const response = await systemService.updateSortOrder(body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: "Sıralama başarıyla güncellendi.",
          });
        }
      } catch (error) {
        console.log(error.response);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },
  },
};
