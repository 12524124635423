export default [
  {
    path: "/journey",
    name: "journey-list-page",
    component: () => import( "../views/journey/journey-list-page.vue"),
  },
  {
    path: "/journey-create",
    name: "journey-create-page",
    component: () => import("../views/journey/journey-create-page.vue"),
  },
  {
    path: "/journey-update/:id",
    name: "journey-update-page",
    component: () => import("../views/journey/journey-update-page.vue"),
    props: true,
  },
  {
    path: "/journey-top",
    name: "journey-top-page",
    component: () => import("../views/journey/journey-top-page.vue"),
  }
];
