import { appAxios } from "./appService";

export default {
  async getEnvindicators() {
    const response = await appAxios.get("/get-envindicators");
    return response.data;
  },
  async getEnvindicator(id) {
    const response = await appAxios.get(`/get-envindicator/${id}`);
    return response.data;
  },
  async createEnvindicator(body) {
    const response = await appAxios.post("/admin/create-envindicator", body);
    return response.data;
  },
  async deleteEnvindicator(id) {
    const response = await appAxios.delete(`/admin/delete-envindicator/${id}`);
    return response.data;
  },
  async updateEnvindicator(id, body) { 
    const response = await appAxios.put(`/admin/update-envindicator/${id}`, body);
    return response.data;
  },
  async updateSortOrder(body) { 
    const response = await appAxios.put(`/admin/update-envindicator-order`, body);
    return response.data;
  }
};