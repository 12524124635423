export default [
    {
      path: "/members",
      name: "member-list-page",
      component: () => import("../views/member/member-list-page.vue"),
    },
    {
      path: "/create-member",
      name: "create-member-page",
      component: () => import("../views/member/create-member-page.vue"),
    },
    {
      path: "/update-member/:id",
      name: "update-member-page",
      component: () => import("../views/member/update-member-page.vue"),
      props: true
    }
  ];
  