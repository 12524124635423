import router from "@/router";
import sideathonService from "@/services/sideathonService";
import { notify } from "@kyvg/vue3-notification";

export default {
    namespaced: true,
    state: {
        ideathons: [],
        loading: false,
    },
    getters: {
        getIdeathons: (state) => state.ideathons,
    },
    mutations: {
        SET_IDEATHONS(state, datas) {
            state.ideathons = datas;
        },
        SET_LOADING(state, loading) {
            state.loading = loading;
        },
    },
    actions: {
        async initIdeathons({ commit }) {
            try {
                const response = await sideathonService.getIdeathons();

                if (response.status === "success") {
                    commit("SET_IDEATHONS", response.data);
                }
            } catch (error) {
                return error.response;
            }
        },

        async getIdeathonById(_, id) {
            try {
                const response = await sideathonService.getIdeathonById(id);
                return response.data;
            } catch (error) {
                console.log(error);
                return error;
            }
        },

        async createIdeathon({ commit }, form) {
            try {
                commit("SET_LOADING", true);

                const body = {
                    title: form.title,
                    description: form.description,
                    year: form.year,
                    image: form.image,
                    link: form.link,
                };

                const response = await sideathonService.createIdeathon(body);

                if (response.status === "success") {
                    notify({
                        type: "success",
                        text: response.message,
                    });
                    router.push({ name: "sideathon-list-page" });
                }

                commit("SET_LOADING", false);
            } catch (error) {
                console.log(error.response);
                notify({
                    type: "error",
                    title: "Error",
                    text: error.response.data.error,
                });
            }
        },

        async deleteIdeathon({ commit }, id) {
            try {
                commit("SET_LOADING", true);
                const response = await sideathonService.deleteIdeathon(id);

                if (response.status === "success") {
                    commit(
                        "SET_IDEATHONS",
                        this.state.sideathon.ideathons.filter(
                            (ideathon) => ideathon.id !== id
                        )
                    );

                    notify({
                        type: "success",
                        text: "Başarıyla silindi.",
                    });

                }
                commit("SET_LOADING", false);
            } catch (error) {
                console.log(error.response);
                notify({
                    type: "error",
                    title: "Error",
                    text: error.response.data.error,
                });
            }
        },

        async updateIdeathon(_, form) {
            try {
                const body = {
                    title: form.title,
                    description: form.description,
                    year: form.year,
                    image: form.image,
                    link: form.link,
                };

                const response = await sideathonService.updateIdeathon(form.id, body);

                if (response.status === "success") {
                    notify({
                        type: "success",
                        text: response.message,
                    });
                    router.push({ name: "sideathon-list-page" });
                }
            } catch (error) {
                console.log(error.response);
                notify({
                    type: "error",
                    title: "Error",
                    text: error.response.data.error,
                });
            }
        },

        async updateSortOrder(_, ids) {
            try {

                const body = {
                    order: ids,
                };

                const response = await sideathonService.updateSortOrder(body);

                if (response.status === "success") {
                    notify({
                        type: "success",
                        text: "Sıralama başarıyla güncellendi.",
                    });
                }
            } catch (error) {
                console.log(error.response);
                notify({
                    type: "error",
                    title: "Error",
                    text: error.response.data.error,
                });
            }
        },

        async updateForMain(_, id) {
            try {
                const response = await sideathonService.updateForMain(id);
                if (response.status === "success") {
                    notify({
                        type: "success",
                        text: "Başarıyla güncellendi.",
                    });
                }

                return response;
            } catch (error) {
                console.log(error.response);
                notify({
                    type: "error",
                    title: "Error",
                    text: error.response.data.error,
                });
            }
        }
    },
};
