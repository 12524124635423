import router from "@/router";
import categoryService from "@/services/pcategoryService";
import { notify } from "@kyvg/vue3-notification";

export default {
    namespaced: true,
    state: {
        aprojects: [],
        loading: false,
    },
    getters: {
        getAProjects: (state) => state.aprojects,
    },
    mutations: {
        SET_APROJECTS(state, aprojects) {
            state.aprojects = aprojects;
        },
        SET_LOADING(state, loading) {
            state.loading = loading;
        },
    },
    actions: {
        async getCategories(_, category_id = null) {
            try {
                const response = await categoryService.getCategories(category_id);
                return response;
            } catch (error) {
                return error.response;
            }
        },

        async getCategoryById(_, id) {
            try {
                const response = await categoryService.getCategoryById(id);
                return response.data;
            } catch (error) {
                console.log(error);
                return error;
            }
        },

        async createCategory({ commit }, form) {
            try {
                commit("SET_LOADING", true);

                const body = {
                    parent_id: form.parent_id,
                    name: form.name,
                    description: form.description,
                    en_name: form.en_name,
                    en_description: form.en_description,
                    image: form.image,
                    active: true,
                };

                const response = await categoryService.createCategory(body);

                if (response.status === "success") {
                    notify({
                        type: "success",
                        text: response.message,
                    });
                    router.push({ name: "pcategory-list-page" });
                }

                commit("SET_LOADING", false);
            } catch (error) {
                console.log(error.response);
                notify({
                    type: "error",
                    title: "Error",
                    text: error.response.data.error,
                });
            }
        },

        async updateCategory(_, form) {
            try {
                const body = {
                    parent_id: form.parent_id,
                    name: form.name,
                    description: form.description,
                    en_name: form.en_name,
                    en_description: form.en_description,
                    active: true
                };

                if (form.image) {
                    body.image = form.image;
                }

                const response = await categoryService.updateCategory(form.id, body);

                if (response.status === "success") {
                    notify({
                        type: "success",
                        text: response.message,
                    });
                    router.push({ name: "pcategory-list-page" });
                }
            } catch (error) {
                console.log(error.response);
                notify({
                    type: "error",
                    title: "Error",
                    text: error.response.data.error,
                });
            }
        },

        async deleteCategory({ commit }, id) {
          try {
            commit("SET_LOADING", true);
            const response = await categoryService.deleteCategory(id);

            if (response.status === "success") {
              notify({
                type: "success",
                text: response.message,
              });
            }
            commit("SET_LOADING", false);
            return response;
          } catch (error) {
            console.log(error.response);
            notify({
              type: "error",
              title: "Error",
              text: error.response.data.error,
            });
          }
        },
    },
};
