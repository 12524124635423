import smanifestService from "@/services/smanifestService";
import { notify } from "@kyvg/vue3-notification";

export default {
    namespaced: true,
    state: {
        loading: false,
    },
    mutations: {
        SET_LOADING(state, loading) {
            state.loading = loading;
        },
    },
    actions: {
        async getManifest() {
            try {
                const response = await smanifestService.getManifest();
                return response.data;
            } catch (error) {
                console.log(error);
            }
        },
        async updateManifest(_, payload) {
            try {
                const body = {
                    short_description: payload.short_description,
                    description: payload.description,
                    signature_title: payload.signature_title,
                };

                if (payload.signature) {
                    body.signature = payload.signature;
                }

                const response = await smanifestService.updateManifest(body);

                if (response.status === "success") {
                    notify({
                        type: "success",
                        title: "Başarılı",
                        text: "Manifesto başarıyla güncellendi.",
                    });
                }

                return response;
            } catch (error) {
                notify({
                    type: "error",
                    title: "Hata",
                    text: error.response.data.error
                });
            }
        },
    },
};
