import router from "@/router";
import shumancentricService from "@/services/shumancentricService";
import { notify } from "@kyvg/vue3-notification";

export default {
    namespaced: true,
    state: {
        categories: [],
        posts: [],
        loading: false,
    },
    getters: {
        getCategories: (state) => state.categories,
        getPosts: (state) => state.posts,
    },
    mutations: {
        SET_CATEGORIES(state, datas) {
            state.categories = datas;
        },
        SET_POSTS(state, datas) {
            state.posts = datas;
        },
        SET_LOADING(state, loading) {
            state.loading = loading;
        },
    },
    actions: {
        async initCategories({ commit }) {
            try {
                const response = await shumancentricService.getCategories();

                if (response.status === "success") {
                    commit("SET_CATEGORIES", response.data);
                }
            } catch (error) {
                return error.response;
            }
        },

        async createCategory({ commit }, form) {
            try {
                commit("SET_LOADING", true);

                const body = {
                    name: form.name,
                };

                const response = await shumancentricService.createCategory(body);
                console.log(response);


                if (response.status === "success") {
                    notify({
                        type: "success",
                        text: response.message,
                    });
                    router.push({ name: "shumancentric-category-page" });
                }

                commit("SET_LOADING", false);
            } catch (error) {
                console.log(error.response);
                notify({
                    type: "error",
                    title: "Error",
                    text: error.response.data.error,
                });
            }
        },

        async deleteCategory({ commit }, id) {
            try {
                commit("SET_LOADING", true);
                const response = await shumancentricService.deleteCategory(id);

                if (response.status === "success") {
                    commit(
                        "SET_CATEGORIES",
                        this.state.shc.categories.filter(
                            (category) => category.id !== id
                        )
                    );

                    notify({
                        type: "success",
                        text: "Başarıyla silindi.",
                    });

                }
                commit("SET_LOADING", false);
            } catch (error) {
                console.log(error.response);
                notify({
                    type: "error",
                    title: "Error",
                    text: error.response.data.error,
                });
            }
        },

        async updateSortOrderForCategory(_, ids) {
            try {

                const body = {
                    order: ids,
                };

                const response = await shumancentricService.updateSortOrderForCategory(body);

                if (response.status === "success") {
                    notify({
                        type: "success",
                        text: "Sıralama başarıyla güncellendi.",
                    });
                }
            } catch (error) {
                console.log(error.response);
                notify({
                    type: "error",
                    title: "Error",
                    text: error.response.data.error,
                });
            }
        },

        async initPost({ commit }) {
            try {
                const response = await shumancentricService.getPosts();

                if (response.status === "success") {
                    commit("SET_POSTS", response.data);
                }
            } catch (error) {
                return error.response;
            }
        },

        async getPostById(_, id) {
            try {
                const response = await shumancentricService.getPostById(id);
                return response.data;
            } catch (error) {
                console.log(error);
                return error;
            }
        },

        async createPost({ commit }, form) {
            try {
                commit("SET_LOADING", true);

                const body = {
                    category_id: form.category_id,
                    title: form.title,
                    en_title: form.en_title,
                    description: form.description,
                    en_description: form.en_description,
                    image: form.image
                };

                const response = await shumancentricService.createPost(body);

                if (response.status === "success") {
                    notify({
                        type: "success",
                        text: response.message,
                    });
                    router.push({ name: "shumancentric-list-page" });
                }

                commit("SET_LOADING", false);
            } catch (error) {
                console.log(error.response);
                notify({
                    type: "error",
                    title: "Error",
                    text: error.response.data.error,
                });
            }
        },

        async updatePost(_, form) {
            try {
                const body = {
                    category_id: form.category_id,
                    title: form.title,
                    en_title: form.en_title,
                    description: form.description,
                    en_description: form.en_description
                };

                if (form.image) {
                    body.image = form.image;
                }

                const response = await shumancentricService.updatePost(form.id, body);

                if (response.status === "success") {
                    notify({
                        type: "success",
                        text: response.message,
                    });
                    router.push({ name: "shumancentric-list-page" });
                }
            } catch (error) {
                console.log(error.response);
                notify({
                    type: "error",
                    title: "Error",
                    text: error.response.data.error,
                });
            }
        },

        async deletePost({ commit }, id) {
            try {
                commit("SET_LOADING", true);
                const response = await shumancentricService.deletePost(id);

                if (response.status === "success") {
                    commit(
                        "SET_POSTS",
                        this.state.shc.posts.filter(
                            (post) => post.id !== id
                        )
                    );

                    notify({
                        type: "success",
                        text: "Başarıyla silindi.",
                    });

                }
                commit("SET_LOADING", false);
            } catch (error) {
                console.log(error.response);
                notify({
                    type: "error",
                    title: "Error",
                    text: error.response.data.error,
                });
            }
        },

        async updateSortOrderForPost(_, ids) {
            try {

                const body = {
                    order: ids,
                };

                const response = await shumancentricService.updateSortOrderForPost(body);

                if (response.status === "success") {
                    notify({
                        type: "success",
                        text: "Sıralama başarıyla güncellendi.",
                    });
                }
            } catch (error) {
                console.log(error.response);
                notify({
                    type: "error",
                    title: "Error",
                    text: error.response.data.error,
                });
            }
        },
    },
};
