import router from "@/router";
import solitionService from "@/services/solitionService";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    solitions: [],
    loading: false,
  },
  getters: {
    getSolitions: (state) => state.solitions,
  },
  mutations: {
    SET_SOLITIONS(state, solitions) {
      state.solitions = solitions;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async initSolitions({ commit }) {
      try {
        const response = await solitionService.getAllSolitions();

        if (response.status === "success") {
          commit("SET_SOLITIONS", response.data);
        }
      } catch (error) {
        return error.response;
      }
    },
    async createSolition({ commit }, solition) {
      try {
        commit("SET_LOADING", true);

        const body = {
          name: solition.name,
          title: solition.title,
          content: solition.content,
          en_name: solition.en_name,
          en_title: solition.en_title,
          en_content: solition.en_content,
          image: solition.image,
        };

        const response = await solitionService.createSolition(body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "solition-list-page" });
        }

        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error.response.data.error);
        notify({
          type: "error",
          text: error.response.data.error,
        });
      }
    },
    async deleteSolition({ commit }, solitionId) {
      try {
        commit("SET_LOADING", true);
        const response = await solitionService.deleteSolition(solitionId);

        if (response.status === "success") {
          commit(
            "SET_SOLITIONS",
            this.state.solition.solitions.filter(
              (solition) => solition.id !== solitionId
            )
          );
        }
        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error);
      }
    },
    async initSolition(_, solitionId) {
      try {
        const response = await solitionService.getSolitionById(solitionId);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async updateSolition(_, solition) {
      try {
        const body = {
          name: solition.name,
          title: solition.title,
          content: solition.content,
          en_name: solition.en_name,
          en_title: solition.en_title,
          en_content: solition.en_content,
          image: solition.image,
        };

        const response = await solitionService.updateSolition(solition.id, body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "solition-list-page" });
        }

      } catch (error) {
        console.log(error.response.data.error);
        notify({
          type: "error",
          text: error.response.data.error,
        });
      }
    },
    async updateSortOrder(_, ids) {
      try {

        const body = {
          order: ids,
        };

        const response = await solitionService.updateSortOrder(body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: "Sıralama başarıyla güncellendi.",
          });
        }
      } catch (error) {
        console.log(error.response);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },
  },
};
