export default [
    {
      path: "/envindicators",
      name: "envindicator-list-page",
      component: () => import( "../views/envindicator/envindicator-list-page.vue"),
    },
    {
      path: "/envindicator-create",
      name: "envindicator-create-page",
      component: () => import("../views/envindicator/envindicator-create-page.vue"),
    },
    {
      path: "/envindicator-update/:id",
      name: "envindicator-update-page",
      component: () => import("../views/envindicator/envindicator-update-page.vue"),
      props: true,
    },
  ];
  