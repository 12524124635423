import { appAxios } from "./appService";

export default {
  async getAllProducts(slug) {
    const response = await appAxios.get(`/get-products/${slug}`);
    return response.data;
  },
  async createProduct(body) {
    const response = await appAxios.post("/admin/create-product", body);
    return response.data;
  },
  async deleteProduct(productId) {
    const response = await appAxios.delete(`/admin/delete-product/${productId}`);
    return response.data;
  },
  async updateProduct(productId, body) {
    const response = await appAxios.put(`/admin/update-product/${productId}`, body);
    return response.data;
  },
  async getProductById(productId) {
    const response = await appAxios.get(`/get-product/${productId}`);
    return response.data;
  },
};
