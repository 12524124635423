export default [
    {
      path: "/pcategory",
      name: "pcategory-list-page",
      component: () => import( "../views/pcategory/pcategory-list-page.vue"),
    },
    {
      path: "/pcategory-create",
      name: "pcategory-create-page",
      component: () => import("../views/pcategory/pcategory-create-page.vue"),
    },
    {
      path: "/pcategory-update/:id",
      name: "pcategory-update-page",
      component: () => import("../views/pcategory/pcategory-update-page.vue"),
      props: true,
    },
  ];
  