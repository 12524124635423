import { appAxios } from "./appService";

export default {
  async getIdeathons() {
    const response = await appAxios.get("/get-ideathons");
    return response.data;
  },
  async getIdeathonById(id) {
    const response = await appAxios.get(`/get-ideathon/${id}`);
    return response.data;
  },
  async createIdeathon(body) {
    const response = await appAxios.post("/admin/create-ideathon", body);
    return response.data;
  },
  async deleteIdeathon(id) {
    const response = await appAxios.delete(`/admin/delete-ideathon/${id}`);
    return response.data;
  },
  async updateIdeathon(id, body) { 
    const response = await appAxios.put(`/admin/update-ideathon/${id}`, body);
    return response.data;
  },
  async updateSortOrder(body) { 
    const response = await appAxios.put(`/admin/update-ideathon-order`, body);
    return response.data;
  },
  async updateForMain(id) { 
    const response = await appAxios.put(`/admin/update-ideathon-main/${id}`,);
    return response.data;
  }
};