import { appAxios } from "./appService";

export default {
  async getCategories() {
    const response = await appAxios.get("/get-pocategories");
    return response.data;
  },
  async createCategory(body) {
    const response = await appAxios.post("/admin/create-pocategory", body);
    return response.data;
  },
  async deleteCategory(id) {
    const response = await appAxios.delete(`/admin/delete-pocategory/${id}`);
    return response.data;
  },
  async updateSortOrderForCategory(body) {
    const response = await appAxios.put(`/admin/update-pocategory-order`, body);
    return response.data;
  },
  async getPosts() {
    const response = await appAxios.get("/get-poposts");
    return response.data;
  },
  async getPostById(id) {
    const response = await appAxios.get(`/get-popost/${id}`);
    return response.data;
  },
  async createPost(body) {
    const response = await appAxios.post("/admin/create-popost", body);
    return response.data;
  },
  async updatePost(id, body) {
    const response = await appAxios.put(`/admin/update-popost/${id}`, body);
    return response.data;
  },
  async deletePost(id) {
    const response = await appAxios.delete(`/admin/delete-popost/${id}`);
    return response.data;
  },
  async updateSortOrderForPost(body) {
    const response = await appAxios.put(`/admin/update-popost-order`, body);
    return response.data;
  },
};