import { appAxios } from "./appService";

export default {
  async getAgents() {
    const response = await appAxios.get("/get-agents");
    return response.data;
  },
  async getAgent(id) {
    const response = await appAxios.get(`/get-agent/${id}`);
    return response.data;
  },
  async createAgent(body) {
    const response = await appAxios.post("/admin/create-agent", body);
    return response.data;
  },
  async updateAgent(id, body) { 
    const response = await appAxios.put(`/admin/update-agent/${id}`, body);
    return response.data;
  },
  async deleteAgent(id) {
    const response = await appAxios.delete(`/admin/delete-agent/${id}`);
    return response.data;
  },
  async updateSortOrder(body) { 
    const response = await appAxios.put(`/admin/update-agent-order`, body);
    return response.data;
  }
};