export default [
    {
      path: "/arge-banner-update",
      name: "arge-banner-update",
      component: () => import("../views/arge/arge-banner-update-page.vue")
    },
    {
      path: "/arge-content-update",
      name: "arge-content-update",
      component: () => import("../views/arge/arge-content-update-page.vue")
    }
  ];
  