import { appAxios } from "./appService";

export default {
  async getMembers() {
    const response = await appAxios.get("/get-members");
    return response.data;
  },
  async createMember(body) {
    const response = await appAxios.post("/admin/create-member", body);
    return response.data;
  },
  async updateMember(id, body) {
    const response = await appAxios.put(`/admin/update-member/${id}`, body);
    return response.data;
  },
  async deleteMember(id) {
    const response = await appAxios.delete(`/admin/delete-member/${id}`);
    return response.data;
  },
  async getMemberById(id) {
    const response = await appAxios.get(`/get-member-by-id/${id}`);
    return response.data;
  },
  async updateSortOrder(body) {
    const response = await appAxios.put(`/admin/update-member-order`, body);
    return response.data;
  },
};
