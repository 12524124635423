import { appAxios } from "./appService";

export default {
  async getCategories(category_id) {
    const response = await appAxios.get(`/get-pcategories?category_id=${category_id}`);
    return response.data;
  },
  async createCategory(body) {
    const response = await appAxios.post("/admin/create-pcategory", body);
    return response.data;
  },
  async deleteCategory(id) {
    const response = await appAxios.delete(`/admin/delete-pcategory/${id}`);
    return response.data;
  },
  async updateCategory(id, body) {
    const response = await appAxios.put(`/admin/update-pcategory/${id}`, body);
    return response.data;
  },
  async getCategoryById(id) {
    const response = await appAxios.get(`/get-pcategory/${id}`);
    return response.data;
  },
};
