export default [
    {
      path: "/agent",
      name: "agent-list-page",
      component: () => import( "../views/agent/agent-list-page.vue"),
    },
    {
      path: "/agent-create",
      name: "agent-create-page",
      component: () => import("../views/agent/agent-create-page.vue"),
    },
    {
      path: "/agent-update/:id",
      name: "agent-update-page",
      component: () => import("../views/agent/agent-update-page.vue"),
      props: true,
    },
  ];
  