import router from "@/router";
import agentService from "@/services/agentService";
import { notify } from "@kyvg/vue3-notification";

export default {
    namespaced: true,
    state: {
        agents: [],
        loading: false,
    },
    getters: {
        getAgents: (state) => state.agents,
    },
    mutations: {
        SET_AGENTS(state, agents) {
            state.agents = agents;
        },
        SET_LOADING(state, loading) {
            state.loading = loading;
        },
    },
    actions: {
        async initAgents({ commit }, page) {
            try {
                const response = await agentService.getAgents(page);

                if (response.status === "success") {
                    commit("SET_AGENTS", response.data);
                }
            } catch (error) {
                return error.response;
            }
        },
        async createAgent({ commit }, form) {
            try {
                commit("SET_LOADING", true);

                const body = {
                    name: form.name,
                    description: form.description,
                    en_description: form.en_description,
                    image: form.image,
                    products: form.products,
                    en_products: form.en_products,
                    catalog: form.catalog,
                    en_catalog: form.en_catalog
                };

                const response = await agentService.createAgent(body);

                if (response.status === "success") {
                    notify({
                        type: "success",
                        text: response.message,
                    });
                    router.push({ name: "agent-list-page" });
                }

                commit("SET_LOADING", false);
            } catch (error) {
                console.log(error.response);
                notify({
                    type: "error",
                    title: "Error",
                    text: error.response.data.error,
                });
            }
        },

        async deleteAgent({ commit }, id) {
            try {
                commit("SET_LOADING", true);
                const response = await agentService.deleteAgent(id);

                if (response.status === "success") {
                    commit(
                        "SET_AGENTS",
                        this.state.agent.agents.filter(
                            (agent) => agent.id !== id
                        )
                    );

                    notify({
                        type: "success",
                        text: response.message,
                    });

                }
                commit("SET_LOADING", false);
            } catch (error) {
                console.log(error.response);
                notify({
                    type: "error",
                    title: "Error",
                    text: error.response.data.error,
                });
            }
        },

        async getAgentById(_, id) {
            try {
                const response = await agentService.getAgent(id);
                return response.data;
            } catch (error) {
                console.log(error);
                return error;
            }
        },

        async updateAgent(_, form) {
            try {
                const body = {
                    name: form.name,
                    description: form.description,
                    en_description: form.en_description,
                    image: form.image,
                    products: form.products,
                    en_products: form.en_products,
                    catalog: form.catalog,
                    en_catalog: form.en_catalog
                };

                if (form.image) {
                    body.image = form.image;
                } 

                const response = await agentService.updateAgent(form.id, body);

                if (response.status === "success") {
                    notify({
                        type: "success",
                        text: response.message,
                    });
                    router.push({ name: "agent-list-page" });
                }
            } catch (error) {
                console.log(error.response);
                notify({
                    type: "error",
                    title: "Error",
                    text: error.response.data.error,
                });
            }
        },

        async updateSortOrder(_, ids) {
            try {
      
              const body = {
                order: ids,
              };
      
              const response = await agentService.updateSortOrder(body);
      
              if (response.status === "success") {
                notify({
                  type: "success",
                  text: "Sıralama başarıyla güncellendi.",
                });
              }
            } catch (error) {
              console.log(error.response);
              notify({
                type: "error",
                title: "Error",
                text: error.response.data.error,
              });
            }
          },
    },
};
