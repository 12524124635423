export default [
    {
        path: "/video",
        name: "video-update-page",
        component: () => import("../views/mainpage/update-video-page.vue"),
    },
    {
        path: "/main-about",
        name: "main-about-update-page",
        component: () => import("../views/mainpage/update-about-page.vue"),
    },
    {
        path: "/feature",
        name: "feature-update-page",
        component: () => import("../views/mainpage/update-feature-page.vue"),
    },
    {
        path: "/founder-slogan",
        name: "founder-slogan-update-page",
        component: () => import("../views/mainpage/update-founder-slogan-page.vue"),
    },
];
