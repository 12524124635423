import router from "@/router";
import journeyService from "@/services/journeyService";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    journeys: [],
    totalPages: 0,
    loading: false,
  },
  getters: {
    getJourneys: (state) => state.journeys,
    getTotalPages: (state) => state.totalPages,
  },
  mutations: {
    SET_JOURNEY(state, journeys) {
      state.journeys = journeys;
    },
    SET_TOTAL_PAGES(state, totalPages) {
      state.totalPages = totalPages - 1;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async initJtop() {
      try {
        const response = await journeyService.getJtop();

        if (response.status === "success") {
          return response.data;
        }
        return null;
      } catch (error) {
        return error.response;
      }
    },
    async updateJtop(_, form) {
      try {
        const body = {
          title: form.title,
          en_title: form.en_title,
          description: form.description,
          en_description: form.en_description,
          founder: form.founder,
          image: form.image,
          signature: form.signature,
        };

        const response = await journeyService.updateJtop(body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
        }
      } catch (error) {
        console.log(error.response);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },


    async initJourneys({ commit }, page) {
      try {
        const response = await journeyService.getAllJourneys(page);

        if (response.status === "success") {
          commit("SET_JOURNEY", response.data);
          commit("SET_TOTAL_PAGES", response.totalPage);
        }
      } catch (error) {
        return error.response;
      }
    },
    async createJourney({ commit }, form) {
      try {
        commit("SET_LOADING", true);

        const body = {
          title: form.title,
          description: form.description,
          en_title: form.en_title,
          en_description: form.en_description,
          image: form.image,
          date: form.date,
        };

        const response = await journeyService.createJourney(body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "journey-list-page" });
        }

        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error.response);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },

    async deleteJourney({ commit }, id) {
      try {
        commit("SET_LOADING", true);
        const response = await journeyService.deleteJourney(id);

        if (response.status === "success") {
          commit(
            "SET_JOURNEY",
            this.state.journey.journeys.filter((journey) => journey.id !== id)
          );
        }
        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error);
      }
    },

    async getJourneyById(_, id) {
      try {
        const response = await journeyService.getJourneyById(id);
        return response.data;
      } catch (error) {
        console.log(error);
        return error;
      }
    },

    async updateJourney(_, form) {
      try {
        const body = {
          title: form.title,
          description: form.description,
          en_title: form.en_title,
          en_description: form.en_description,
          isActive: form.isActive,
          date: form.date,
        };

        if (form.image) {
          body.image = form.image;
        }

        const response = await journeyService.updateJourney(form.id, body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "journey-list-page" });
        }

      } catch (error) {
        console.log(error.response);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },

    async updateSortOrder(_, ids) {
      try {

        const body = {
          order: ids,
        };

        const response = await journeyService.updateSortOrder(body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: "Sıralama başarıyla güncellendi.",
          });
        }
      } catch (error) {
        console.log(error.response);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },
  },
};
