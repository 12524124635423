import { appAxios } from "./appService";

export default {
  async getManifest() {
    const response = await appAxios.get(`/get-manifest`);
    return response.data;
  },
  async updateManifest(body) {
    const response = await appAxios.put(`/admin/update-manifest`, body);
    return response.data;
  },
};
