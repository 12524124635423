export default [
    {
      path: "/sideathon",
      name: "sideathon-list-page",
      component: () => import( "../views/sideathon/sideathon-list-page.vue"),
    },
    {
      path: "/sideathon-create",
      name: "sideathon-create-page",
      component: () => import("../views/sideathon/sideathon-create-page.vue"),
    },
    {
      path: "/sideathon-update/:id",
      name: "sideathon-update-page",
      component: () => import("../views/sideathon/sideathon-update-page.vue"),
      props: true,
    },
  ];
  