export default [
    {
      path: "/shumancentric",
      name: "shumancentric-list-page",
      component: () => import( "../views/shumancentric/shumancentric-list-page.vue"),
    },
    {
      path: "/shumancentric-create",
      name: "shumancentric-create-page",
      component: () => import("../views/shumancentric/shumancentric-create-page.vue"),
    },
    {
      path: "/shumancentric-update/:id",
      name: "shumancentric-update-page",
      component: () => import("../views/shumancentric/shumancentric-update-page.vue"),
      props: true,
    },
    {
      path: "/shumancentric-category",
      name: "shumancentric-category-page",
      component: () => import( "../views/shumancentric/shumancentric-category-page.vue"),
    },
    {
      path: "/shumancentric-category-create",
      name: "shumancentric-category-create",
      component: () => import( "../views/shumancentric/shumancentric-category-create-page.vue"),
    }
  ];
  