import router from "@/router";
import blogService from "@/services/blogService";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    blogs: [],
    loading: false,
  },
  getters: {
    getBlogs: (state) => state.blogs,
  },
  mutations: {
    SET_BLOGS(state, blogs) {
      state.blogs = blogs;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async initBlogs({ commit }) {
      try {
        const response = await blogService.getAllBlogs();

        if (response.status === "success") {
          commit("SET_BLOGS", response.data);
        }
      } catch (error) {
        return error.response;
      }
    },
    async createBlog({ commit }, blog) {
      try {
        commit("SET_LOADING", true);

        const body = {
          title: blog.title,
          content: blog.content,
          en_title: blog.en_title,
          en_content: blog.en_content,
          image: blog.image,
          pdf: blog.pdf,
          is_active: blog.is_active,
          is_featured: blog.is_featured,
        };

        const response = await blogService.createBlog(body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "blog-list-page" });
        }

        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error.response.data.error);
        notify({
          type: "error",
          text: error.response.data.error,
        });
      }
    },
    async deleteBlog({ commit }, bId) {
      try {
        commit("SET_LOADING", true);
        const response = await blogService.deleteBlog(bId);

        if (response.status === "success") {
          commit(
            "SET_BLOGS",
            this.state.blog.blogs.filter((blog) => blog.id !== bId)
          );
        }
        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error);
      }
    },
    async initBlog(_, bId) {
      try {
        const response = await blogService.getBlogById(bId);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async updateBlog(_, blog) {
      try {
        const body = {
          title: blog.title,
          content: blog.content,
          en_title: blog.en_title,
          en_content: blog.en_content,
          image: blog.image,
          pdf: blog.pdf,
          is_active: blog.is_active,
          is_featured: blog.is_featured,
        };

        const response = await blogService.updateBlog(blog.id, body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "blog-list-page" });
        }
      } catch (error) {
        console.log(error.response.data.error);
        notify({
          type: "error",
          text: error.response.data.error,
        });
      }
    },

    async updateSortOrder(_, ids) {
      try {

        const body = {
          order: ids,
        };

        const response = await blogService.updateSortOrder(body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: "Sıralama başarıyla güncellendi.",
          });
        }
      } catch (error) {
        console.log(error.response);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },

  },
};
