import { appAxios } from "./appService";

export default {
  async getAllSystems() {
    const response = await appAxios.get("/get-systems");
    return response.data;
  },
  async createSystem(body) {
    const response = await appAxios.post("/admin/create-system", body);
    return response.data;
  },
  async deleteSystem(id) {
    const response = await appAxios.delete(`/admin/delete-system/${id}`);
    return response.data;
  },
  async getSystemById(id) {
    const response = await appAxios.get(`/get-system/${id}`);
    return response.data;
  } ,
  async updateSystem(id, body) { 
    const response = await appAxios.put(`/admin/update-system/${id}`, body);
    return response.data;
  },
  async updateSortOrder(body) { 
    const response = await appAxios.put(`/admin/update-system-order`, body);
    return response.data;
  }
};